.created {
    font-style: italic;
    font-size: 10px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.Mui-ToolbarDropZone 
{
    display: none !important;
}